import { createMuiTheme } from '@material-ui/core/styles'

// Override default theme
// https://material-ui.com/customization/default-theme/?expand-path=$.palette.primary
const theme = createMuiTheme({
  palette: {
    base: {
      white: '#fff',
      black: '#000'
    },
    background: {
      default: '#fff'
    },
    primary: {
      light: '#4482D0', // blue_3
      dark: '#1463B1', // blue_2
      main: '#002E70', // blue_0
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#C4E0F7', // blue_5
      main: '#7CAEE5', // blue_4
      dark: '#115190', // blue_1
      contrastText: '#ffffff'
    },
    error: {
      light: '#FFEEEE', // red_3
      main: '#EA7A7A', // red_2
      dark: '#D85B5B', // red_1
      contrastText: '#ffffff'
    },
    warning: {
      light: '#FFE183', // yellow-2
      main: '#FEA45A', // orange_2
      dark: '#EA872D', // orange_1
      contrastText: '#ffffff'
    },
    success: {
      light: '#A4D868', // green_4
      main: '#82BB26', // green_3
      dark: '#147214', // green_1
      contrastText: '#ffffff'
    },
    text: {
      primary: '#646569',
      secondary: '#ffffff',
      dark: '#4D4D4F'
    },
    green: {
      light: '#F0F7E7', // green_2
      main: '#D0E6A7', // green_5
      dark: '#66992C', // green_2,
      contrastText: '#CAE19E'
    },
    blue: {
      light: '#E7F4FF',
      main: '#F2F9FF',
      dark: '#13396A'
    },
    gray: {
      light: '#F3F7FB',
      main: '#828282',
      dark: '#C9D1DA'
    },
    aqua: {
      main: ' #2F9FA5',
      light: '#BEF2F6'
    },
    turquoise600: '#71F3F8',
    purple50: '#F8F7FD',
    purple600: '#7A68EE',
    purple100: '#EBE8FB',
    purpleHaze600: '#2F2F6D',
    purpleHaze100: '#E0E0E9',
    deepPurple200: '#B7B7C1',
    deepPurple300: '#707085',
    deepPurple600: '#111233',
    grey200: '#84848C',
    grey300: '#585866',
    grey600: '#23232C',
    actionBlue100: '#C7F1FF',
    actionBlue500: '#4BC9F4',
    actionBlue600: '#1FAEE0',
    helpBlue600: '#509EF9',
    green200: '#A3F0AB',
    green300: '#86E28F',
    green600: '#56C060',
    slate50: '#F8F9FB',
    slate100: '#EEF2F5',
    slate200: '#D6DDE3',
    slate300: '#BAC4CD',
    red50: '#FFD6D6',
    red600: '#FF4848',
    orange50: '#FFF1E3',
    orange600: '#FFA048',
    deepPurple75: 'rgb(17 18 51 / 75%)',
    modal: {
      main: '#092548',
      dots: '#A4CBF4',
      dotsActive: '#145DA5',
      lastUpdate: '#8D9298'
    },
    subState: {
      creditRepair: {
        dropRequested: {
          background: '#FFDEDE',
          color: '#D85B5B'
        },
        drop: {
          background: '#FFDEDE',
          color: '#BC4A4A'
        },
        definitiveDrop: {
          background: '#ED9D9A',
          color: '#8B3C3C'
        },
        graduated: {
          background: '#7CAEE5',
          color: '#13396A'
        },
        programOpening: {
          background: '#A8DDDE',
          color: '#005B63'
        },
        savingOpening: {
          background: '#84CBCD',
          color: '#005B63'
        },
        savingProgresive: {
          background: '#51AAAC',
          color: '#005B63'
        },
        savingIncomplete: {
          background: '#FFC47C',
          color: '#E67826'
        },
        savingPaused: {
          background: '#ED9D9A',
          color: '#BC4A4A'
        }
      },
      debt: {
        debtAssignment: {
          color: '#E57826',
          background: '#FFF5ED',
          border: '#E57826'
        },
        projectingNegotiation: {
          color: '#E57826',
          background: '#FFEDDE',
          border: '#E57826'
        },
        negotiationStrategy: {
          color: '#D85B5B',
          background: '#FFE2CA',
          border: '#D85B5B'
        },
        preparingNegotiation: {
          color: '#D85B5B',
          background: '#FFD1AA',
          border: '#D85B5B'
        },
        negotiation: {
          color: '#20508C',
          background: '#F2F9FF',
          border: '#20508C'
        },
        earnedDiscount: {
          color: '#13396A',
          background: '#A4CBF4',
          border: '#13396A'
        },
        liquidationStructuredPayment: {
          color: '#37C2CF',
          background: '#ECFDFF',
          border: '#37C2CF'
        },
        liquidationInProcess: {
          color: '#30BC86',
          background: '#E7FFF8',
          border: '#30BC86'
        },
        liquidated: {
          color: '#30BC86',
          background: '#CCEFE5',
          border: '#30BC86'
        },
        liquidatedWithCredit: {
          color: '#30BC86',
          background: '#CCEFE5',
          border: '#30BC86'
        },
        closed: {
          color: 'white',
          background: '#30BC86',
          border: '#30BC86'
        },
        refinancing: {
          color: '#37C2CF',
          background: '#ECFDFF',
          border: '#37C2CF'
        },
        cancelled: {
          color: '#D85B5B',
          background: '#FFE2CA',
          border: '#D85B5B'
        }
      }
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: ['Montserrat', 'Rubik', 'sans-serif'].join(','),
    body1: {
      color: '#585866', // grey300
      fontSize: '1rem'
    },
    footer: {
      fontSize: '1rem'
    }
  },
  shadows: Array(25).fill('none')
})

theme.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        background: `linear-gradient(to right, ${theme.palette.purpleHaze600}, ${theme.palette.deepPurple600})`,
      }
    }
  },
  MuiButtonBase: {
    root: {
      '&.MuiChip-clickable': {
        border: `1px solid ${theme.palette.primary.dark}`
      }
    }
  },
  MuiButton: {
    root: {
      borderRadius: 24,
      fontWeight: 700
    },
    textPrimary: {
      color: theme.palette.primary.light,
      '&:hover': {
        color: theme.palette.primary.dark,
        background: 'transparent',
        backgroundColor: 'none'
      },
      '&$disabled': {
        background: 'transparent',
        backgroundColor: 'none'
      }
    },
    containedPrimary: {
      '& .MuiButton-endIcon': {
        '& svg': {
          color: theme.palette.text.secondary
        }
      },
      '&:disabled': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.actionBlue100,
      },
      '&:hover': {
        backgroundColor: theme.palette.actionBlue500,
      },
      backgroundColor: theme.palette.actionBlue500,
    },
    containedSecondary: {
      backgroundColor: theme.palette.base.white,
      color: theme.palette.actionBlue500,
      borderColor: theme.palette.actionBlue500,
      borderWidth: '1px',
      borderStyle: 'solid',
      '&:hover': {
        backgroundColor: theme.palette.base.white,
      },
    },
    outlinedPrimary: {
      '&:disabled': {
        color: theme.palette.primary.main + '80',
        borderColor: theme.palette.primary.main + '80'
      }
    },
    endIcon: {
      marginLeft: '0.8rem'
    },
    containedSizeLarge: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem'
      }
    }
  },
  MuiTypography: {
    h1: {
      fontSize: '2rem' // 32px
    },
    h2: {
      fontSize: '1.5rem' // 24px
    },
    h3: {
      fontSize: '1.375rem' // 22px
    },
    h4: {
      fontSize: '1.125rem' // 18px
    },
    h5: {
      fontSize: '1rem' // 16px
    }
  },
  MuiList: {
    root: {
      borderColor: theme.palette.gray.main,
      borderWidth: '1px',
      borderStyle: 'solid'
    }
  },
  MuiPaper: {
    root: {
      boxShadow: 'none'
    }
  },
  MuiContainer: {
    root: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: '4rem',
        paddingLeft: '4rem'
      }
    }
  },
  MuiSwitch: {
    root: {
      height: 40,
      width: 54,
      left: '-8px'
    },
    track: {
      opacity: 1,
      backgroundColor: theme.palette.slate100
    },
    thumb: {
      width: 22,
      height: 22
    },
    switchBase: {
      color: theme.palette.slate200
    },
    colorSecondary: {
      '&$checked': {
        color: theme.palette.actionBlue600
      },
      '&$checked + $track': {
        opacity: 1,
        backgroundColor: theme.palette.slate100
      },
    },
  },
  MuiRadio: {
    root: {
      color: theme.palette.gray.dark
    }
  },
  MuiInput: {
    input: {
      color: theme.palette.grey200,
      backgroundColor: theme.palette.purple50,
      borderRadius: '8px',
      padding: '15px 26px 15px'
    },
    underline: {
      '&:before': {
        borderBottom: 0,
        display: 'none'
      },
      '&:after': {
        borderBottom: 0,
        display: 'none'
      },
      '&$error': {
        '& input': {
          backgroundColor: theme.palette.error.light
        }
      }
    }
  },
  MuiFormHelperText: {
    root: {
      '&$error': {
        color: theme.palette.error.dark,
        marginTop: '0.5rem',
        marginLeft: '1.5625rem'
      }
    }
  },
  MuiAvatar: {
    colorDefault: {
      color: theme.palette.secondary.main
    }
  },
  MuiInputAdornment: {
    positionStart: {
      '& p': {
        color: theme.palette.secondary.dark
      }
    }
  },
  MuiDialog: {
    paper: {
      margin: 0,
      borderTopRightRadius: '1.6rem',
      borderTopLeftRadius: '1.6rem',
      borderBottomRightRadius: '0',
      borderBottomLeftRadius: '0',
      [theme.breakpoints.up('md')]: {
        borderRadius: '1rem'
      }
    },
    paperFullWidth: {
      width: '100%'
    },
    scrollPaper: {
      alignItems: 'flex-end',
      [theme.breakpoints.up('md')]: {
        alignItems: 'center'
      }
    }
  },
  MuiDialogContent: {
    root: {
      padding: 0,
      '&:first-child': {
        paddingTop: 0
      }
    }
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(122, 104, 238,0.7)'
    }
  },
  MuiCheckbox: {
    root: {
      color: theme.palette.primary.light
    },
    checked: {
      color: theme.palette.primary.light
    },
    colorPrimary: {
      '&.Mui-checked ': {
        color: theme.palette.primary.light
      }
    }
  },
  MuiInputLabel: {
    filled: {
      color: theme.palette.grey200,
      top: '-2px',
      fontSize: '15px',
      transform: 'translate(25px, 20px) scale(1)',
      '&.MuiInputLabel-shrink ': {
        fontWeight: 'normal',
        lineHeight: '14px',
        fontSize: '1rem',
        paddingLeft: '15px'
      }
    }
  },
  MuiFilledInput: {
    root: {
      borderRadius: '8px',
      maxHeight: '48px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      backgroundColor: theme.palette.blue.main,
      background: theme.palette.blue.main,
      '&:hover, &:focus, &.Mui-focus': {
        backgroundColor: theme.palette.blue.main,
        background: theme.palette.blue.main
      },
      '&.MuiFilledInput-adornedEnd': {
        backgroundColor: theme.palette.purple50,
      },
      '&.Mui-error, &.Mui-error .MuiInputBase-input': {
        background: theme.palette.error.light
      },
      '&.Mui-error, &.Mui-error .MuiFilledInput-adornedEnd': {
        background: theme.palette.error.light
      },
      '&.Mui-error, &.Mui-error .MuiIconButton-colorError': {
        color: theme.palette.error.dark
      },
    },
    input: {
      color: theme.palette.grey600,
      backgroundColor: theme.palette.purple50,
      background: theme.palette.purple50,
      padding: '18px 25px 12px',
      borderRadius: '8px',
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px'
    },
    underline: {
      '&:before': {
        borderBottom: 0,
        display: 'none'
      },
      '&:after': {
        borderBottom: 0,
        display: 'none'
      }
    }
  },
  MuiFormControlLabel: {
    root: {
      display: 'flex',
      alignItems: 'flex-start'
    },
    label: {
      fontSize: '0.75rem',
      paddingTop: '0.5625rem',
      '& strong': {
        color: theme.palette.secondary.main
      },
      '& a': {
        color: theme.palette.secondary.main,
        fontWeight: 600,
        textDecoration: 'none'
      }
    }
  },
  MuiFormLabel: {
    root: {
      fontSize: '1rem',
      '&.Mui-error': {
        color: '#DD6464'
      }
    }
  },

  MuiSelect: {
    icon: {
      color: theme.palette.purple600
    },
    select: {
      borderRadius: '8px',
      backgroundColor: theme.palette.grey.light
    }
  },
  MuiAccordion: {
    root: {
      border: '1px solid ' + theme.palette.background.default,
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      }
    },
    rounded: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderRadius: '0.5rem',
      '&:first-child': {
        borderTopRightRadius: '0.5rem',
        borderTopLeftRadius: '0.5rem'
      },
      '&:first-child, &:last-child': {
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem'
      }
    }
  },
  MuiAccordionSummary: {
    root: {
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56
      }
    },
    content: {
      '&$expanded': {
        margin: '12px 0'
      }
    }
  },
  MuiAccordionDetails: {
    root: {
      backgroundColor: theme.palette.gray.light,
      padding: theme.spacing(2)
    }
  },
  MuiFormGroup: {
    root: {
      '& .$MuiFormControlLabel-root': {
        '& .$MuiFormControlLabel-label': {
          fontSize: '1rem',
          fontWeight: 600,
          color: theme.palette.primary.light
        }
      }
    }
  }
}

export default theme
