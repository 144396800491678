import amplitude from 'amplitude-js'

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

let isInitialized = false

export const initAmplitude = () => {
  if (isInitialized || !AMPLITUDE_API_KEY) return

  amplitude.getInstance().init(AMPLITUDE_API_KEY)

  isInitialized = true
}

export const setAmplitudeUserId = (userId = '') => {
  if (!userId) return

  initAmplitude()

  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = (properties = {}) => {
  initAmplitude()

  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmplitudeData = (event = '', properties = {}) => {
  if (!event) return

  initAmplitude()

  amplitude.getInstance().logEvent(event, properties)
}
