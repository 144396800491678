import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      '.No_records_content': {
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        padding: '1rem'
      }
    }
  })
)

const GlobalStyles = () => {
  useStyles()

  return null
}

export default GlobalStyles
