import Api from 'api'

class UserService extends Api {
  setPassword = (input = '', id = '', token = '', isOtpFlow = false) => {
    const SET_PASSWORD_PATH = isOtpFlow ? 'set-password' : ''
    const URL = `${process.env.REACT_APP_CLIENTS_API_URL}/account/${id}/${SET_PASSWORD_PATH}`
    const options = {
      method: 'PUT',
      url: URL,
      data: {
        user: {
          password: input
        }
      },
      headers: {
        'Content-type': 'Application/json',
        Authorization: token
      }
    }

    return this.request(options)
  }

  getResumen = (userId, token) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/account/resume`,
      data: {
        user: {
          id: userId
        }
      },
      headers: {
        'Content-type': 'Application/json',
        Authorization: token
      }
    }

    return this.request(options)
  }

  getPaymentReference = (userId, token) => {
    const url = `${process.env.REACT_APP_CLIENTS_API_URL}/account/${userId}/payment_reference`

    return this.request({
      url: url,
      method: 'get',
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    })
  }

  registerDevice = (userId, token, pushToken) => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/device/register`,
      data: {
        user: {
          type: 'web',
          token: pushToken,
          uid: userId
        }
      },
      headers: {
        'Content-type': 'Application/json',
        Authorization: token
      }
    }

    return this.request(options)
  }
}

export default UserService
