import AuthStore from './authStore'
import UserProgramStore from './userProgramStore'
import ModalOnBoardingStore from './modalOnBoardingStore'
import UserPlatformStore from './userPlatformStore'

const stores = {
  authStore: AuthStore,
  userProgramStore: UserProgramStore,
  modalOnBoardingStore: ModalOnBoardingStore,
  userPlatformStore: UserPlatformStore
}

export default stores
