import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'mobx-react'
import { AsyncTrunk } from 'mobx-sync'
import * as serviceWorker from './serviceWorker'
import Routes from 'components/pages/Routes'
import store from 'stores/'
import theme from 'theme/'
// Moment
import moment from 'moment'
import 'moment/locale/es'
import 'moment/locale/fr'
// Numeral
import { numeralLocale } from 'utils/numeral'
// malinchex
import malinchex from '@resuelve/malinchex'
// Sentry
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
// LogRocket
import LogRocket from 'logrocket'
import GlobalStyles from './GlobalStyles'

const trunk = new AsyncTrunk(store, { storage: localStorage })

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register(`firebase-messaging-sw.js`)
    .then(registration => {
      console.log('[SW]: SCOPE: ', registration.scope)
      return registration.scope
    })
    .catch(err => err)
}

const init = async () => {
  if (process.env?.NODE_ENV !== 'development') {
    LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID)
  }

  const malinchexBaseURL = process.env.REACT_APP_MALINCHEX_BASE_URL

  await trunk.init()

  if (malinchexBaseURL) {
    await malinchex.init(malinchexBaseURL, navigator.language)
  }

  moment.locale(navigator.language)
  numeralLocale(navigator.language)

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  })

  ReactDOM.render(
    <Provider {...store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </Provider>,
    document.getElementById('root')
  )
}

init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
