import base64 from 'base-64'
import utf8 from 'utf8'
import t from '../i18n'
import moment from 'moment'
import numeral from 'numeral'
import { DATE_FORMATS, DEBT_STATUS, MX, SUPPORTED_COUNTRIES } from './const'

export const isCurrentLocation = (link = null) => {
  return window.location.pathname === link
}

export const getYesterdayDate = () => {
  return moment()
    .subtract(1, 'days')
    .format('DD MMM YYYY')
}

export const dateByFormat = date => {
  if (!date) return ''
  return moment(date, 'DD/MM/YYYY').format('DD MMM YYYY')
}

export const dateFormat = date => {
  if (!date) return ''
  return moment(date).format('DD MMM YYYY')
}

export const getAntiquityByDays = days => {
  if (!days) return ''
  const [MONTHS, DAYS] = DATE_FORMATS
  const format = days > 31 ? MONTHS : DAYS
  const value = moment().diff(moment().subtract(days, DAYS), format)
  const text =
    format === MONTHS
      ? t('sidebar.months', {}, value)
      : t('sidebar.days', {}, value)
  return `${text}`
}

export const decodeToken = storedToken => {
  const [data] = storedToken.split('.')
  const token = data.replace(/_/g, '/').replace(/-/g, '+')
  const decodeToken = base64.decode(token)
  const json = utf8.decode(decodeToken)

  return JSON.parse(json).meta
}

export const moneyFormat = amount => {
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

export const isEmpty = obj => {
  for (var _ in obj) {
    return false
  }
  return true
}

export const timeElapsed = time => {
  const sec = time % 60
  const min = Math.floor(time / 60)
  return {
    minutes: min,
    seconds: sec
  }
}

export const cardNumberFormat = value => {
  const trimmed = value.substring(0, 16)
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
  const onlyNumbers = trimmed.replace(/[^\d]/g, '')
  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter(group => !!group).join(' ')
  )
}

export const financialEntitiesAndAmountsFormat = (debts = []) => {
  if (debts.length === 0) return ''

  const debtFormatted = debt => {
    return `${debt?.financial_entity || ''} ${debt?.amount || ''}`
  }

  const lastDebt =
    debts.length > 1
      ? ` ${t('commons.and')} ${debtFormatted(debts[debts.length - 1])}`
      : ''

  return debts.map(debt => debtFormatted(debt)).join(', ') + lastDebt
}

export const financialEntityAndAmountFormat = (debt = null) => {
  if (!debt) return ''

  return `${debt?.financial_entity || ''} ${debt?.amount || ''} \n${dateFormat(
    debt?.date
  ) || ''}`
}

export const passwordMask = value => {
  return value.replace(/\d(?=\d{1})/g, '*')
}

export const cardBank = card => {
  const visaRgx = /^4/
  const masterCardRgx = /^5[1-5]/
  const isVisa = visaRgx.test(card)
  const isMasterCard = masterCardRgx.test(card)
  return isVisa && !isMasterCard
    ? 'visa'
    : !isVisa && isMasterCard
    ? 'mastercard'
    : null
}

export const cardMask = value => {
  return value.replace(/\d(?=\d{4})/g, '*')
}

export const b64toBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/jpeg' })
}

export const greetingMessage = () => {
  const today = new Date()
  const currentHour = today.getHours()

  if (currentHour < 12) {
    return t('greeting_message.good_morning')
  } else if (currentHour < 18) {
    return t('greeting_message.good_afternoon')
  } else {
    return t('greeting_message.good_evening')
  }
}

export const lastFourDigits = number => {
  return number.trim().slice(-4)
}

export const creditNumberFormat = (number = '') => `****${number}`

export const debtStatusLabel = status => {
  switch (status) {
    case DEBT_STATUS.new:
    case DEBT_STATUS.medium:
    case DEBT_STATUS.high:
      return t('debts.status.new')

    case DEBT_STATUS.negotiation:
      return t('debts.status.negotiation')

    case DEBT_STATUS.liquidation:
      return t('debts.status.liquidation')

    case DEBT_STATUS.liquidation_structured_payment:
      return t('debts.status.liquidation_structured_payment')

    case DEBT_STATUS.paid:
      return t('debts.status.paid')

    case DEBT_STATUS.cancelled:
      return t('debts.status.cancelled')

    case DEBT_STATUS.closed:
      return t('debts.status.closed')

    case DEBT_STATUS.non_closed_account:
      return t('debts.status.non_closed_account')

    case DEBT_STATUS.non_closed_account_payment:
      return t('debts.status.non_closed_account_payment')

    case DEBT_STATUS.liquidation_portfolio_payment:
      return t('debts.status.liquidation_portfolio_payment')

    case DEBT_STATUS.paid_structured_payment:
      return t('debts.status.paid_structured_payment')

    case DEBT_STATUS.liquidation_outside_of_program:
      return t('debts.status.liquidation_outside_of_program')

    default:
      return t('debts.status.default')
  }
}

export const debtStatusClass = (status, classes) => {
  switch (status) {
    case DEBT_STATUS.new:
      return classes.statusNew

    case DEBT_STATUS.medium:
      return classes.statusNewMedium

    case DEBT_STATUS.high:
      return classes.statusNewHigh

    case DEBT_STATUS.negotiation:
      return classes.statusNegotiation

    case DEBT_STATUS.liquidation:
    case DEBT_STATUS.paid:
    case DEBT_STATUS.paid_structured_payment:
    case DEBT_STATUS.liquidation_structured_payment:
    case DEBT_STATUS.liquidation_portfolio_payment:
      return classes.statusPaid

    case DEBT_STATUS.cancelled:
      return classes.statusCancelled

    case DEBT_STATUS.closed:
      return classes.statusClosed

    case DEBT_STATUS.non_closed_account_payment:
      return classes.statusNonClosedAccountPayment

    default:
      return classes.statusDefault
  }
}

export const abbrAmount = amount => numeral(amount).format('$0a')

export const formatAmount = amount => numeral(amount).format('$0,0')

export const cleanAmount = amount =>
  Number(amount.replace(/[^0-9.-]+/g, '')).toFixed(2)

export const getSplittedAmountAndCurrency = (amount = '') => {
  if (!amount) return []
  return amount.split(' ')
}

export const formatAmountWithCommas = amount => {
  if (!amount) return 0

  const [splitedAmount] = amount.split(',')

  return splitedAmount.replaceAll('.', ',') ?? 0
}

export const debtDiscount = (debt = {}, country = '') => {
  if (!debt?.amount || !debt?.total_payment || !country) return ''

  // Validación para montos en Euros y Pesos Colombianos
  if (country !== MX) {
    const [debtAmount] = getSplittedAmountAndCurrency(debt?.amount)
    const [debtTotalPayment] = getSplittedAmountAndCurrency(debt?.total_payment)
    const amount = cleanAmount(formatAmountWithCommas(debtAmount))
    const totalPayment = cleanAmount(formatAmountWithCommas(debtTotalPayment))

    if (!amount || !totalPayment) return ''

    return Math.floor(((amount - totalPayment) / amount) * 100)
  }

  const amount = cleanAmount(debt.amount)
  const totalPayment = cleanAmount(debt.total_payment)

  if (!amount || !totalPayment) return ''

  return Math.floor(((amount - totalPayment) / amount) * 100)
}

export const isDiscountActive = debt => {
  if (!debt || !debt.activities.length) {
    return false
  }
  const last = debt.activities[0]
  const now = moment()
  const days = moment(last.expiration).diff(now, 'days', true)
  return days >= 1
}

export const lastestActivity = debt => {
  if (debt && debt?.activities && debt.activities.length >= 1) {
    return debt.activities[0]
  }
  return null
}

export const debtStatus = (status, antiquity) => {
  if (status === 'new') {
    return antiquity <= 1 ? 'new' : antiquity === 2 ? 'medium' : 'high'
  }
  return status
}

export const isMobile = () => {
  const match = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return match.some(item => {
    return navigator.userAgent.match(item)
  })
}

export const nameInitials = name => {
  return name
    .match(/\b(\w)/g)
    .join('')
    .toUpperCase()
}

export const supportedCountries = (country = '') =>
  SUPPORTED_COUNTRIES.includes(country)

export const DEBT_SUB_STATES = (
  bank = '',
  discount = '',
  paymentsTotal = 0,
  pendingPaymentsTotal = 0
) => {
  return [
    {
      id: 'debt_assignment',
      label: t('debt_sub_states.label.debt_assignment'),
      text: t('debt_sub_states.text.debt_assignment')
    },
    {
      id: 'projecting_negotiation',
      label: t('debt_sub_states.label.projecting_negotiation'),
      text: t('debt_sub_states.text.projecting_negotiation')
    },
    {
      id: 'negotiation_strategy',
      label: t('debt_sub_states.label.negotiation_strategy'),
      text: t('debt_sub_states.text.negotiation_strategy')
    },
    {
      id: 'preparing_negotiation',
      label: t('debt_sub_states.label.preparing_negotiation'),
      text: t('debt_sub_states.text.preparing_negotiation')
    },
    {
      id: 'negotiation',
      label: t('debt_sub_states.label.negotiation'),
      text: t('debt_sub_states.text.negotiation')
    },
    {
      id: 'earned_discount',
      label: t('debt_sub_states.label.earned_discount'),
      text: t('debt_sub_states.text.earned_discount')
    },
    {
      id: 'liquidation_structured_payment',
      label: t('debt_sub_states.label.liquidation_structured_payment'),
      text: t('debt_sub_states.text.liquidation_structured_payment', {
        payments_total: paymentsTotal,
        pending_payments_total: pendingPaymentsTotal
      })
    },
    {
      id: 'liquidation_in_process',
      label: t('debt_sub_states.label.liquidation_in_process'),
      text: t('debt_sub_states.text.liquidation_in_process', {
        bank: bank,
        discount: discount
      })
    },
    {
      id: 'liquidated',
      label: t('debt_sub_states.label.liquidated'),
      text: t('debt_sub_states.text.liquidated')
    },
    {
      id: 'liquidated_with_credit',
      label: t('debt_sub_states.label.liquidated_with_credit'),
      text: t('debt_sub_states.text.liquidated_with_credit')
    },
    {
      id: 'closed',
      label: t('debt_sub_states.label.closed'),
      text: t('debt_sub_states.text.closed')
    },
    {
      id: 'refinancing',
      label: t('debt_sub_states.label.refinancing'),
      text: t('debt_sub_states.text.refinancing')
    },
    {
      id: 'cancelled',
      label: t('debt_sub_states.label.cancelled'),
      text: t('debt_sub_states.text.cancelled')
    }
  ]
}

export const CREDIT_REPAIR_SUB_STATES = [
  {
    id: 'drop_requested',
    label: t('credit_repair_sub_states.label.drop_requested'),
    text: t('credit_repair_sub_states.text.')
  },
  {
    id: 'drop',
    label: t('credit_repair_sub_states.label.drop'),
    text: t('credit_repair_sub_states.text.')
  },
  {
    id: 'definitive_drop',
    label: t('credit_repair_sub_states.label.definitive_drop'),
    text: t('credit_repair_sub_states.text.definitive_drop')
  },
  {
    id: 'graduated',
    label: t('credit_repair_sub_states.label.graduated'),
    text: t('credit_repair_sub_states.text.graduated')
  },
  {
    id: 'program_opening',
    label: t('credit_repair_sub_states.label.program_opening'),
    text: t('credit_repair_sub_states.text.program_opening')
  },
  {
    id: 'saving_opening',
    label: t('credit_repair_sub_states.label.saving_opening'),
    text: t('credit_repair_sub_states.text.saving_opening')
  },
  {
    id: 'saving_progresive',
    label: t('credit_repair_sub_states.label.saving_progresive'),
    text: t('credit_repair_sub_states.text.saving_progresive')
  },
  {
    id: 'saving_incomplete',
    label: t('credit_repair_sub_states.label.saving_incomplete'),
    text: t('credit_repair_sub_states.text.saving_incomplete')
  },
  {
    id: 'saving_paused',
    label: t('credit_repair_sub_states.label.saving_paused'),
    text: t('credit_repair_sub_states.text.saving_paused')
  }
]
