import React from 'react'
// Material components
import { makeStyles, Backdrop } from '@material-ui/core'
// Lottie
import Lottie from 'lottie-react'
import loader from 'assets/animations/loader-bravo.json'

/**
 * @typedef{{
 * open: boolean
 * closeAction?: function
 * }} Props
 */
const BackdropCustom = ({ open = false, closeAction = null }) => {
  const classes = useStyles()

  const animationStyle = {
    maxWidth: '200px'
  }

  return (
    <Backdrop className={classes.backdrop} open={open} onClick={closeAction}>
      <Lottie animationData={loader} loop autoplay style={animationStyle} />
    </Backdrop>
  )
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: theme.palette.deepPurple75
  }
}))

export default BackdropCustom
