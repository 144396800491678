export const getOSInfo = () => {
  const userAgent = navigator.userAgent;
  let osName = "Unknown OS";

  if (/windows nt 10.0/i.test(userAgent)) {
    osName = "Windows 10";
  } else if (/windows nt 6.2/i.test(userAgent)) {
    osName = "Windows 8";
  } else if (/windows nt 6.1/i.test(userAgent)) {
    osName = "Windows 7";
  } else if (/windows nt 6.0/i.test(userAgent)) {
    osName = "Windows Vista";
  } else if (/windows nt 5.1|windows xp/i.test(userAgent)) {
    osName = "Windows XP";
  } else if (/macintosh|mac os x/i.test(userAgent)) {
    osName = "Mac OS X";
  } else if (/mac_powerpc/i.test(userAgent)) {
    osName = "Mac OS 9";
  } else if (/android/i.test(userAgent)) {
    osName = "Android";
  } else if (/linux/i.test(userAgent)) {
    osName = "Linux";
  } else if (/iphone|ipad|ipod/i.test(userAgent)) {
    osName = "iOS";
  } else if (/cros/i.test(userAgent)) {
    osName = "Chrome OS";
  }

  return osName;
}
