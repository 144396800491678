export const events = {
  USER_LOOKUP: 'USER_LOOKUP',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_OTP_REQUESTED: 'USER_OTP_REQUESTED',
  USER_OTP_CODE_VALIDATION: 'USER_OTP_CODE_VALIDATION',
  USER_PAYMENT_REFERENCE: 'USER_PAYMENT_REFERENCE',

  MENU_ITEM_DEBTS: 'MENU_ITEM_DEBTS',
  MENU_ITEM_MOVEMENTS: 'MENU_ITEM_MOVEMENTS',
  MENU_ITEM_PLAN: 'MENU_ITEM_PLAN'
}
