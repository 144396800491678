import Api from 'api'
import { SUPPORTED_COUNTRIES } from 'utils/const'

class AuthService extends Api {
  signIn = input => {
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLIENTS_API_URL}/users/login`,
      data: { input, supported_countries: SUPPORTED_COUNTRIES }
    }

    return this.request(options)
  }

  login = (input, password) => {
    const data = {
      user: { input, password, supported_countries: SUPPORTED_COUNTRIES }
    }
    const url = `${process.env.REACT_APP_CLIENTS_API_URL}/users/auth`

    return this.post(url, data)
  }
}

export default AuthService
