
// Mobx
import { action, decorate } from 'mobx'
// Services
import UserPlatformService from 'services/userPlatformService'
// Store
import authStore from '../authStore'

const userPlatformService = new UserPlatformService()

class UserPlatformStore {
  saveUserPlatform = async () => {
    try {
      const { accessToken = '', account = {} } = authStore
      const { id = '' } = account

      const { data } = await userPlatformService.saveUserPlatform(id, accessToken)

      if (data?.success) {
        console.info('User platform was saved correctly')
      } else {
        console.warn('User platform was not saved correctly')
      }
    } catch (error) {
      console.error(`Error to save user platform: ${error}`)
    }
  }
}

decorate(UserPlatformStore, {
  saveUserPlatform: action,
})

export default new UserPlatformStore()
