import React, { Suspense } from 'react'
// Material
import { Box, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// i18n
import t from 'i18n'
// Components
import Loader from 'components/molecules/loader'

const PageStatus = ({ children }) => {
  const classes = useStyles()

  return (
    <Suspense fallback={<Loader />}>
      <Box>
        <Box className={classes.logo}>
          <a href='/'>
            <img
              src='./assets/images/go-bravo-transparent-logo.png'
              alt={t('app.company_name')}
              width='120px'
            />
          </a>
        </Box>
        <Grid container>
          {children}
        </Grid>
      </Box>
    </Suspense>
  )
}

const useStyles = makeStyles(theme => ({
  "@global": {
    "body": {
      backgroundImage: `url("./assets/images/background-go-bravo-transparent.png"), linear-gradient(to right, ${theme.palette.deepPurple600}, ${theme.palette.purple600})`,
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'auto'
      }
    }
  },
  content: {
    textAlign: 'center',
    top: '25vh',
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    left: '5%',
    top: '5%'
  }
}))

export default PageStatus
