import React, { Component } from 'react'
// Components
import CustomButton from 'components/molecules/button'
import PageStatus from 'components/pages/pageStatus'
// Material ui
import { Box, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// i18n
import t from 'i18n'
// Sentry
import { sentryCaptureMessage } from 'utils/sentry'

class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false
    }
  }

  static getDerivedStateFromError (error = '') {
    return { hasError: true, error }
  }

  componentDidCatch (_, errorInfo) {
    this.setState({ errorInfo })

    const errorMessage = errorInfo.componentStack.toString()

    if (errorMessage) {
      sentryCaptureMessage(errorMessage)
    }
  }

  render () {
    const { hasError, errorInfo } = this.state
    const { classes, children } = this.props

    if (!hasError) return children

    return (
      <PageStatus>
        <Box className={classes.container}>
          <Box className={classes.content}>
            <Typography className={classes.title}>
              {t('error_boundary.title')}
            </Typography>
            <Typography className={classes.text}>
              {t('error_boundary.text')}
            </Typography>
            <Typography className={classes.description}>
              {t('error_boundary.description')}
            </Typography>
            <details className={classes.detailsContent} open>
              <summary className={classes.errorDetails}>
                {t('error_boundary.see_details')}
              </summary>
              {errorInfo && errorInfo.componentStack.toString()}
            </details>
            <Box className={classes.buttonContent}>
              <CustomButton
                fullWidth
                text={t('error_boundary.home_page')}
                color='primary'
                size='large'
                iconPosition='end'
                variant='contained'
                onClick={() => {
                  window.location.href = '/'
                }}
              />
              <CustomButton
                fullWidth
                text={t('error_boundary.reload_page')}
                color='primary'
                size='large'
                iconPosition='end'
                variant='contained'
                onClick={() => {
                  window.location.reload()
                }}
              />
            </Box>
          </Box>
          <Box className={classes.illustrationContent}>
            <img
              className={classes.illustration}
              src='./assets/images/error-page-illustration.png'
              alt={t('app.company_name')}
            />
          </Box>
        </Box>
      </PageStatus>
    )
  }
}

const styles = theme => ({
  detailsContent: {
    backgroundColor: theme.palette.base.white,
    padding: '15px',
    marginBottom: '15px'
  },
  illustration: {
    width: '100%'
  },
  illustrationContent: {
    top: '-15vh',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  title: {
    color: theme.palette.base.white,
    fontSize: '8rem',
    fontWeight: 500,
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.5rem',
    }
  },
  text: {
    color: theme.palette.base.white,
    fontSize: '2rem',
    fontWeight: 500,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    }
  },
  description: {
    color: theme.palette.base.white,
    fontSize: '1.5rem',
    fontWeight: 500,
    marginBottom: '3.75rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    }
  },
  container: {
    position: 'relative',
    marginTop: '15vh',
    textAlign: 'center',
    flex: 1
  },
  content: {
    margin: 'auto',
    maxWidth: '560px',
    position: 'relative',
    zIndex: 5
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-around',
    '& button': {
      maxWidth: '40%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        marginBottom: '5px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  errorDetails: {
    marginBottom: '32px',
    padding: '10px',
    backgroundColor: theme.palette.base.white
  }
})

export default withStyles(styles, { withTheme: true })(ErrorBoundary)
