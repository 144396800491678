import Analytics from 'analytics-node'
import { getCurrentLocale } from 'utils/locale'

let isInitialized = false
let analytics = null

const setCountryEnv = () => {
  const currentLocale = getCurrentLocale()

  switch (currentLocale) {
    case 'es':
      return new Analytics(process.env.REACT_APP_SEGMENT_KEY_ES)
    case 'co':
      return new Analytics(process.env.REACT_APP_SEGMENT_KEY_CO)
    default:
      return new Analytics(process.env.REACT_APP_SEGMENT_KEY_MX)
  }
}

export const cleanSegmentInitializer = () => (isInitialized = false)

const initialize = () => {
  if (isInitialized) return

  analytics = setCountryEnv()
  isInitialized = true
}

export const identify = (userId = null, traits = {}) => {
  if (!analytics) return

  analytics.identify({
    userId: userId,
    traits: traits
  })
}

export const track = (
  eventName,
  userId = null,
  anonymousId = 'anonymous_user',
  properties = {}
) => {
  initialize()

  analytics.track({
    userId: userId,
    anonymousId: !userId ? anonymousId : null,
    event: eventName,
    properties: properties
  })
}

export const page = (name, userId = null, properties = null) => {
  initialize()

  analytics.page({
    userId: userId,
    anonymousId: !userId ? 'anonymous_user' : null,
    category: 'Mi Espacio Web',
    name: name,
    properties: {
      url: window.location.href,
      path: window.location.pathname,
      ...properties
    }
  })
}
