import numeral from 'numeral'
// Utils
import { NUMERALS } from 'utils/const'

/**
 * @typedef{{
 * lang: string
 * actions: object
 * }} Props
 */
export const numeralLocale = (lang = null, actions = {}) => {
  if (!lang || numeral.locales[lang]) return
  const options = NUMERALS
  const params = { ...options, ...actions }
  numeral.register('locale', lang, params)
  numeral.locale(lang)
}
