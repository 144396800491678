import { REGEX_PASSWORD_RULES } from 'utils/regex'
import t from 'i18n'

export const validateSignInField = value => {
  if (!value) {
    return t('form_validations.required_field')
  }

  return isValidMobilePhone(value) || isValidEmail(value)
    ? ''
    : t('form_validations.phone_number_or_email_required')
}

export const validatePasswordField = value => {
  if (!value) {
    return t('form_validations.required_field')
  }

  if (!isValidPassword(value)) {
    return t('form_validations.password_format')
  }

  return ''
}

export const isValidPassword = password =>
  REGEX_PASSWORD_RULES.test(password.trim())

export const isValidEmail = email => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(email)
}

export const validateEmail = value => {
  let error
  if (!value) {
    error = t('form_validations.required_field')
  } else if (!isValidEmail(value)) {
    error = t('form_validations.email_required')
  }
  return error
}

export const notEmpty = value => {
  let error
  if (!value) {
    error = t('form_validations.required_field')
  }
  return error
}

const isValidMobilePhone = text => {
  const regex = /^\d{9,10}$/
  return regex.test(text)
}

export const validPhoneFormat = value => {
  let error
  if (!value) {
    error = t('form_validations.required_field')
  } else if (!isValidMobilePhone(value)) {
    error = t('form_validations.ten_digits_phone_number')
  }
  return error
}

export const validOTPFormat = value => {
  let error
  const re = /^\d{6}$/
  if (!value || !re.test(String(value))) {
    error = t('form_validations.six_digits_verification')
  }
  return error
}

export const selectedOption = value => {
  let error
  if (!value) {
    error = 'Debes aceptar los términos para continuar'
  }
  return error
}

export const validPostCode = value => {
  let error
  const re = /^\d{5}$/
  if (!value || !re.test(String(value))) {
    error = 'Ingresa tu código postal'
  }
  return error
}

export const validCVV = value => {
  let error
  const re = /^\d{3}$/
  if (!value || !re.test(String(value))) {
    error = 'Ingresa el CVV de tu tarjeta'
  }
  return error
}

export const validCardNumber = value => {
  let error
  const re = /^\d{16}$/
  if (!value || !re.test(String(value))) {
    error = 'Ingresa los 16 dígitos de tu tarjeta'
  }
  return error
}
