const LAST_PAGE_LOCAL_STORAGE = 'lastPageBeforeLogin'
const AVAILABLE_PAGES = ['movements', 'debts', 'plan', 'settings']

/**
 * @typedef{{
 * page: string
 * }} Props
 */
export const saveLastPageBeforeLogin = (page = '') => {
  if (!page || !AVAILABLE_PAGES.includes(page)) return

  deleteLastPageBeforeLogin()

  localStorage.setItem(LAST_PAGE_LOCAL_STORAGE, page)
}

export const getLastPageBeforeLogin = () => {
  return localStorage.getItem(LAST_PAGE_LOCAL_STORAGE)
}

export const deleteLastPageBeforeLogin = () => {
  localStorage.removeItem(LAST_PAGE_LOCAL_STORAGE)
}
