import {
  SUB_STATE_COUNTRIES_FILTER,
  DEBT_STATUS,
  DEBT_SUB_STATUS
} from 'utils/const'

import t from 'i18n'

const PAYMENT_PAID_STATUS = 'paid'

export const getStructuredPaymentsTotal = (payments = []) => {
  const paymentsTotal = payments.length
  const pendingPaymentsTotal = payments.filter(
    p => p.status === PAYMENT_PAID_STATUS
  ).length

  return {
    pendingPaymentsTotal,
    paymentsTotal
  }
}

/**
 * Sub state:
 * - Asignando el valor `paid`, sí la deuda tiene estatus `non_closed_account_document_requested`
 * - Asignando el valor `cancelled` en sub etapa, sí la deuda tiene el valor `preparing_negotiation` en sub etapas.
 */
export const setStatusInDebts = (debts = []) => {
  return debts.map((debt = {}) => {
    if (debt?.status === DEBT_STATUS.non_closed_account_document_requested) {
      debt.status = DEBT_STATUS.paid
    }

    if (debt?.status === DEBT_STATUS.cancelled) {
      debt.sub_state = DEBT_SUB_STATUS.cancelled
    }

    return debt
  })
}

/**
 * Sub states: Asignar los valores para filtrar deudas:
 * - Propiedad `sub_state` para reparadoras de México
 * - Propiedad `status` para reparadoras de España y Colombia.
 */
export const getDebtFilter = ({ buildDebts = [], country = '' }) => {
  return [
    ...new Set(
      buildDebts.map((debt = {}) => {
        return isCountryEnableInSubState(country)
          ? debt?.sub_state
          : debt?.status
      })
    )
  ].map(info => ({
    value: info,
    text: isCountryEnableInSubState(country)
      ? t(`debt_sub_states.label.${info}`)
      : t(`debts.status.${info}`)
  }))
}

export const isCountryEnableInSubState = (country = '') => {
  return SUB_STATE_COUNTRIES_FILTER.includes(country)
}
