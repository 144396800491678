import contacts from './contacts.json'

export const getCurrentLocale = () => sessionStorage.getItem('locale')

export const isSupportingByLocale = (locale = '') => {
  const currentLocale = getCurrentLocale()
  return currentLocale === locale
}

export const saveCurrentLocale = (locale = '') =>
  sessionStorage.setItem('locale', locale)

export const getContactByReference = (path = '') => {
  const locale = getCurrentLocale()

  if (locale) {
    return (
      `${locale}.${path}`.split('.').reduce((o, k) => o && o[k], contacts) || {}
    )
  }

  return {}
}
