import LogRocket from 'logrocket'
import { LOG_ROCKET_TYPE_ERRORS } from './const'

export const logIssueByIdentify = ({ identify, typeError }) => {
  LogRocket.identify(identify)

  const typeErrorMessage = () => {
    switch (typeError) {
      case LOG_ROCKET_TYPE_ERRORS.login:
        return `[Error: Login - ${identify}]`

      case LOG_ROCKET_TYPE_ERRORS.signIn:
        return `[Error: SignIn - ${identify}]`

      case LOG_ROCKET_TYPE_ERRORS.signInInvalidStatus:
        return `[Error: SignIn invalid status - ${identify}]`

      case LOG_ROCKET_TYPE_ERRORS.otp:
        return `[Error: OTP - ${identify}]`

      default:
        return `[Error: ${identify}]`
    }
  }

  LogRocket.captureMessage(typeErrorMessage())
}
