import React, { lazy, Suspense } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
// Amplitude
import { setAmplitudeUserId, setAmplitudeUserProperties } from 'utils/amplitude'
import { SnackbarProvider } from 'notistack'
// Mobx
import { observer, inject } from 'mobx-react'
// Const
import { DEFAULT_LOCALE, LOCALES_AVAILABLE } from 'utils/const'
// Utils
import { saveCurrentLocale } from 'utils/locale'
import { saveLastPageBeforeLogin } from 'utils/lastPageBeforeLogin'
// Custom components
import ErrorBoundary from './errorBoundary'
import Loader from 'components/molecules/loader'
import ScrollToTop from 'components/molecules/scrollTop'
import Backdrop from 'components/molecules/backdrop'
// Segment
import { cleanSegmentInitializer } from 'utils/analytics'
// Access
const SignIn = lazy(() => import('./signin'))
const Login = lazy(() => import('./login'))
// OTP
const OTPValidate = lazy(() => import('./otp'))
// Password
const Password = lazy(() => import('./password'))
const PasswordForgot = lazy(() => import('./password/forgot'))
// 404
const NotFound = lazy(() => import('./404'))
// Dashboard
const Dashboard = lazy(() => import('./dashboard'))

const PREFIX_LOCALE = '/:locale'

const AppRoutes = ({ authStore, userProgramStore }) => {
  const { isLoggedIn, hasSession, account } = authStore
  const { country = '' } = account
  const { downloading } = userProgramStore

  if (isLoggedIn) {
    const options = {
      id: account.tracker_id ?? '',
      email: account.email ?? '',
      push_token: account.token ?? '',
      app_id: account.id ?? '',
      status: account.status ?? ''
    }

    setAmplitudeUserId(account?.tracker_id)
    setAmplitudeUserProperties(options)
  }

  const saveLastPageLogin = (location = {}) => {
    const { search = '' } = location
    const searchMatch = search ? search.match(/page=([^&]*)/) : ''

    if (searchMatch) {
      const [, page = ''] = searchMatch

      if (page) {
        saveLastPageBeforeLogin(page)
      }
    }
  }

  return (
    <Suspense fallback={<Loader />}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <ScrollToTop />
          <Backdrop open={downloading} />
          <ErrorBoundary>
            <Switch>
              <Route path={`/:locale(${LOCALES_AVAILABLE})`}>
                {({ match, location }) => {
                  const { params } = match
                  const { locale } = params || {}

                  saveLastPageLogin(location)
                  saveCurrentLocale(locale)

                  if (country !== locale) {
                    cleanSegmentInitializer()
                  }

                  if (hasSession && country && country !== locale) {
                    saveCurrentLocale(country)

                    return <Redirect to={`/${country}/dashboard`} />
                  }

                  return (
                    <>
                      <Route
                        exact
                        path={`${PREFIX_LOCALE}/login`}
                        render={() => {
                          if (hasSession) {
                            return <Redirect to={`/${locale}/dashboard`} />
                          }
                          return isLoggedIn ? (
                            <Login />
                          ) : (
                            <Redirect to={`/${locale}`} />
                          )
                        }}
                      />
                      <Route
                        path={`${PREFIX_LOCALE}/dashboard`}
                        render={() =>
                          hasSession ? (
                            <Dashboard />
                          ) : (
                            <Redirect to={`/${locale}/login`} />
                          )
                        }
                      />
                      <Route
                        exact
                        path={`${PREFIX_LOCALE}/validate`}
                        component={OTPValidate}
                      />
                      <Route
                        exact
                        path={`${PREFIX_LOCALE}/set-password`}
                        component={Password}
                      />
                      <Route
                        exact
                        path={`${PREFIX_LOCALE}/forgot-password`}
                        render={() =>
                          isLoggedIn ? (
                            <PasswordForgot />
                          ) : (
                            <Redirect to={`/${locale}/`} />
                          )
                        }
                      />
                      <Route
                        exact
                        path={PREFIX_LOCALE}
                        render={() => {
                          if (hasSession)
                            return <Redirect to={`/${locale}/dashboard`} />
                          if (isLoggedIn)
                            return <Redirect to={`/${country}/login`} />
                          return <SignIn />
                        }}
                      />
                    </>
                  )
                }}
              </Route>
              <Route
                exact
                path='/'
                render={({ location }) => {
                  saveLastPageLogin(location)

                  return <Redirect to={`/${DEFAULT_LOCALE}`} />
                }}
              />
              <Route path='*' component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </Router>
      </SnackbarProvider>
    </Suspense>
  )
}

export default inject('authStore', 'userProgramStore')(observer(AppRoutes))
