// Mobx
import { action, computed, decorate, observable, toJS } from 'mobx'
import { ignore } from 'mobx-sync'
// Moment
import moment from 'moment'
// Services
import AuthService from 'services/authService'
import OTPService from 'services/otpService'
import UserService from 'services/userService'
// Utils
import { decodeToken, supportedCountries } from 'utils/utils'
import { logIssueByIdentify } from 'utils/log_rocket'
import {
  OTP_STATUS,
  INVALID_STATUS,
  LOG_ROCKET_TYPE_ERRORS
} from '../../utils/const'
// Validations
import { isValidEmail } from 'forms/validations'
// Store
import userProgram from '../userProgramStore'
// Analytics
import { track } from 'utils/analytics'
import { sendAmplitudeData } from 'utils/amplitude'
import { events } from 'utils/events'

const authService = new AuthService()
const otpService = new OTPService()
const userService = new UserService()
const INDEFINITE = 'indefinite'

class AuthStore {
  account = {}
  accessToken = {}
  accessExpiration = null
  userData = {}
  loading = false
  otp = {}
  rememberSession = false

  get isLoggedIn () {
    return this.account?.id && this.account?.active && this.accessExpiration
  }

  get hasSession () {
    const { status, id } = toJS(this.userData)
    return status && id
  }

  get userNameInitials () {
    const fullName = this.fullName
    if (!fullName) return ''
    let [first, second] = fullName.split(' ')
    first = first ? first.charAt(0) : ''
    second = second ? second.charAt(0) : ''
    return `${first}${second}`
  }

  get userNames () {
    const account = toJS(this.account)
    return account && account.profile && account.profile.names
      ? account.profile.names
      : ''
  }

  get fullName () {
    const account = toJS(this.account)
    return account && account.profile
      ? `${account.profile?.names || ''} ${account.profile?.first_surname ||
          ''}`
      : ''
  }

  hasUserPartialCredit = ({ hasCredit = false, status = '' }) =>
    hasCredit && status === 'active'

  hasUserRefinancing = ({ hasRefinancing = false, status = '' }) =>
    hasRefinancing && status === 'active'

  cleanSession = () => {
    this.account = {}
    this.userData = {}
    this.accessToken = {}
    this.accessExpiration = null
  }

  cleanAccess = () => {
    if (!this.rememberSession) {
      this.account = {}
      this.accessToken = {}
      this.accessExpiration = null
    }
    this.loading = false
    this.otp = {}
    this.userData = {}
    userProgram.cleanUserProgram()
  }

  setAccessExpirationHour = () =>
    (this.accessExpiration = moment().add(1, 'hours'))

  setAccessExpirationIndefinite = () => (this.accessExpiration = INDEFINITE)

  sessionExpired = () => {
    if (this.accessExpiration === INDEFINITE || !this.accessExpiration) {
      return
    }

    const expiration = moment(this.accessExpiration)
    const isExpired = moment().isAfter(expiration)

    if (isExpired) {
      this.cleanAccess()
    }
  }

  login = async password => {
    const user =
      'input' in this.account ? this.account.input : this.account.email
    try {
      this.loading = true
      const response = await authService.login(user, password)
      const { data } = response
      this.loading = false

      if (data && data.user && data.user.token) {
        const userData = decodeToken(data.user.token)

        if (
          this.hasUserPartialCredit({
            status: userData?.status,
            hasCredit: userData?.has_credit
          })
        ) {
          return { success: true, has_partial_credit: true }
        }

        if (
          this.hasUserRefinancing({
            status: userData?.status,
            hasRefinancing: userData?.has_refinancing
          })
        ) {
          return { success: true, has_refinancing: true }
        }

        this.userData = userData

        if (this.userData?.tracker_id) {
          track(events.USER_LOGIN, this.userData.tracker_id, null, {
            status: this.userData.status,
            email: this.userData.email
          })
        }

        sendAmplitudeData(events.USER_LOGIN)

        return { success: true }
      }
      logIssueByIdentify({
        identify: user,
        typeError: LOG_ROCKET_TYPE_ERRORS.login
      })
      return { success: false }
    } catch (error) {
      logIssueByIdentify({
        identify: user,
        typeError: LOG_ROCKET_TYPE_ERRORS.login
      })
      this.loading = false
      return { success: false }
    }
  }

  signIn = async ({ value, isSessionIndefinite }) => {
    try {
      this.loading = true

      const { data = {} } = await authService.signIn(value)
      const { user = {} } = data && data.token ? decodeToken(data.token) : {}
      const { errors } = data
      this.loading = false

      // Usuario con país no soportado
      if (
        ('country' in user && !supportedCountries(user?.country)) ||
        errors?.user === 'user_not_supported_due_to_origins_country'
      ) {
        return {
          success: false,
          error: 'Not Found'
        }
      }

      // Usuario con crédito activo
      if (
        this.hasUserPartialCredit({
          status: user?.status,
          hasCredit: user?.has_credit
        })
      ) {
        return { success: true, has_partial_credit: true }
      }

      // Usuario con refinanciamiento
      if (
        this.hasUserRefinancing({
          status: user?.status,
          hasRefinancing: user?.has_refinancing
        })
      ) {
        return { success: true, has_refinancing: true }
      }

      // Usuario activo
      if (data?.token && user?.active) {
        this.rememberSession = isSessionIndefinite
        isSessionIndefinite
          ? this.setAccessExpirationIndefinite()
          : this.setAccessExpirationHour()
        this.account = user
        this.accessToken = data.token

        if (this.account?.tracker_id) {
          track(events.USER_LOOKUP, this.account.tracker_id, null, {
            status: this.account.status,
            email: this.account.email
          })
        }

        sendAmplitudeData(events.USER_LOOKUP)

        return { success: true }
      }

      // Usuario inactivo o baja
      if (!user?.active && INVALID_STATUS.includes(user?.status)) {
        logIssueByIdentify({
          identify: value,
          typeError: LOG_ROCKET_TYPE_ERRORS.signInInvalidStatus
        })
        return { success: false, error: user.status }
      }

      // Usuario que se le envió OTP
      if (data?.uid && OTP_STATUS.includes(data?.status)) {
        this.setOTP(data)
      }

      return { success: false }
    } catch (error) {
      this.loading = false

      logIssueByIdentify({
        identify: value,
        typeError: LOG_ROCKET_TYPE_ERRORS.signIn
      })

      return {
        success: false,
        error: error?.response?.data?.message || ''
      }
    }
  }

  setOTP = data => (this.otp = data)

  validateOTP = async code => {
    try {
      this.loading = true
      const { uid, receiver } = this.otp
      const response = await otpService.validate(uid, receiver, code)
      const { status, data } = response
      this.loading = false
      if (status === 200 && data && data.uid) {
        this.setOTP(response.data)
        this.account = decodeToken(response.data.token)
        this.accessToken = response.data.token
        sendAmplitudeData(events.USER_OTP_CODE_VALIDATION)
        return { success: true }
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      if (error && error.response) this.setOTP(error.response.data)
      return { success: false, status: error?.response?.data?.status }
    }
  }

  requestOTP = async input => {
    try {
      this.loading = true
      const response = await otpService.request(input)
      const { status, data } = response
      this.loading = false
      if (status === 200 && data && data.uid) {
        this.setOTP(response.data)
        sendAmplitudeData(events.USER_OTP_REQUESTED)
        return { success: true }
      }
      logIssueByIdentify({
        identify: input,
        typeError: LOG_ROCKET_TYPE_ERRORS.otp
      })
      return { success: false }
    } catch (error) {
      logIssueByIdentify({
        identify: input,
        typeError: LOG_ROCKET_TYPE_ERRORS.otp
      })
      this.loading = false
      return { success: false }
    }
  }

  clearOTP = () => (this.otp = {})

  get otpRequestMethod () {
    const otp = toJS(this.otp)
    return isValidEmail(otp.receiver) ? 'email' : 'phone'
  }

  savePassword = async (input = '', isOtpFlow = false) => {
    try {
      this.loading = true
      const { id } = this.account
      const response = await userService.setPassword(
        input,
        id,
        this.accessToken,
        isOtpFlow
      )
      const { status, data } = response
      this.loading = false
      if (status === 200 && data?.user?.active) {
        this.setAccessExpirationHour()
        this.userData = data.user
        return { success: true }
      }
      return { success: false }
    } catch (error) {
      this.loading = false
      return { success: false, error: error?.response?.data?.message || '' }
    }
  }
}

decorate(AuthStore, {
  // Auth
  account: [observable],
  rememberSession: [observable],
  accessToken: [observable],
  loading: [observable, ignore],
  userData: [observable],
  signIn: action,
  login: action,
  cleanAccess: action,
  sessionExpired: action,
  userNames: computed,
  fullName: computed,
  userNameInitials: computed,
  isLoggedIn: computed,
  // OTP
  otp: [observable, ignore],
  setOTP: action,
  validateOTP: action,
  otpRequestMethod: computed
})

export default new AuthStore()
