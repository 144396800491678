import { runInAction, decorate, action, observable } from 'mobx'

const ONBOARDING_MODAL = 'SHOW_ONBOARDING_MODAL'

class ModalOnBoardingStore {
  showOnboardingModal = true

  getOnboardingModalStatus = () => {
    const modalStatus = localStorage.getItem(ONBOARDING_MODAL)

    runInAction(() => {
      this.showOnboardingModal = JSON.parse(modalStatus) ?? true
    })
  }

  setOnboardingModalStatus = status => {
    localStorage.setItem(ONBOARDING_MODAL, status)

    runInAction(() => {
      this.showOnboardingModal = status
    })
  }
}

decorate(ModalOnBoardingStore, {
  showOnboardingModal: observable,
  setOnBoardingModalStatus: action,
  getOnboardingModalStatus: action
})

export default new ModalOnBoardingStore()
